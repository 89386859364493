'use strict';

const slickSlider = require('slick-carousel');
// import libs
// Require jQuery (Fancybox dependency)
window.$ = window.jQuery = require('jquery');
// bootstrap
const popper = require('popper.js');

// bootstrap
const bootstrap = require('bootstrap');

// masonry
const masonry = require('masonry-layout/dist/masonry.pkgd.js');


// mask
const mask = require('jquery.maskedinput/src/jquery.maskedinput.js');

// Fancybox
const fancybox = require('@fancyapps/fancybox');


// paginationjs
const paginationjs = require('paginationjs');

$(document).ready(function () {

    const header = $('.js-header');

    $('.row-masonry').masonry({
        itemSelector: '.block-masonry',
    });


    //
    // var container = document.querySelector('#masonry-grid');
    // var msnry = new Masonry( container, {
    //    columnWidth: 200,
    //    itemSelector: '.item'
    // });


    // Set active class for Header
    $(window).scroll(function () {
        let top = $(this).scrollTop();
        header.toggleClass('active', top > header.height());
    });



    // show more
    $('.showMoreEvents').click(function () {
        $('.hideBlock').addClass('show');
        $(this).addClass('hide');
    });

    // Smooth scroll
    var $window = $(window);
    var windowHeight = $window.height();
    var windowWidth = $window.width();

    // soft scroll
    $(".scrollTo").on("click", function (event) {
        // исключаем стандартную реакцию браузера
        event.preventDefault();
        // $('.burger').removeClass('active');
        // $('.menu').removeClass('active');
        // $('body').removeClass('active');
        var id  = $(this).attr('href');
        if(windowWidth < 768) {
            var top = $(id).offset().top - 80;
        } else {
            var top = $(id).offset().top - 80;
        }
        $('body,html').animate({scrollTop: top}, 500);
        // анимируем переход к блоку, время: 500 мс

        // находим высоту, на которой расположен блок
    });

    if(windowWidth > 901) {
        $('.hot-span').text('Горящие вакансии:')
    } else {
        $('.hot-span').text('Горящие вакансии');
        $('.hot-span').click(function () {
            $('.pop-up-overlay-wrapper').addClass('active');
            $('body, html').addClass('active');
            $('.pop-up-general-block').removeClass('active');
            $('#hotPopUp').addClass('active');
        });
    };


    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);


    //  slider
    // var $status = $('.pagingInfo');
    //
    // $slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    //    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
    //    var i = (currentSlide ? currentSlide : 0) + 1;
    //    $status.text(i + '/' + slick.slideCount);
    // });

$('.slider-main').slick({
       dots: false,
       infinite: true,
       slidesToShow: 1,
       slidesToScroll: 1,
       arrows: false,
       autoplay: true,
       autoplaySpeed: 2000,
       fade: true,
});
$('.cli-slider').slick({
       dots: false,
       infinite: true,
       slidesToShow: 6,
       slidesToScroll: 1,
       arrows: false,
       autoplay: true,
       autoplaySpeed: 1000,
       responsive: [
            {
               breakpoint: 1200,
               settings: {
                   slidesToShow: 4,
                   dots: false,
                   slidesToScroll: 1,
                }
            },
            {
               breakpoint: 768,
               settings: {
                   slidesToShow: 3,
                   dots: false,
                   slidesToScroll: 1,
                }
            }
        ]
});
$('.slider-rev').slick({
       dots: false,
       infinite: true,
       slidesToShow: 1,
       slidesToScroll: 1,
       fade: true,
       nextArrow: $('.slider-rev-arrow-next'),
       prevArrow: $('.slider-rev-arrow-prev'),
       autoplay: true,
       autoplaySpeed: 4000,
});
   $('.smi-slider').slick({
       dots: false,
       infinite: true,
       slidesToShow: 4,
       slidesToScroll: 1,
       nextArrow: $('.slider-smi-arrow-next'),
       prevArrow: $('.slider-smi-arrow-prev'),
       autoplay: true,
       autoplaySpeed: 3000,
       responsive: [
            {
               breakpoint: 1200,
               settings: {
                   slidesToShow: 3,
                   dots: false,
                   slidesToScroll: 1,
                }
            },
            {
               breakpoint: 992,
               settings: {
                   slidesToShow: 2,
                   dots: false,
                   slidesToScroll: 1,
                }
            },
            {
               breakpoint: 768,
               settings: {
                   slidesToShow: 1,
                   dots: false,
                   slidesToScroll: 1,
                }
            }
        ]
   });

   // tabs
   $('.vac-tab li').click(function () {
       var attr = $(this).attr('data-tab');
       $('.tab-vac-body').removeClass('active');
       $('.tab-vac-body[data-tab="' + attr + '"]').addClass('active');
       $('.vac-tab li').removeClass('active');
       $(this).addClass('active');
   });

   $('.vac-tag-js').click(function () {
       var attr = $(this).attr('data-tab');;
       console.log(attr);
       $('.tab-vac-body').removeClass('active');
       $('.tab-vac-body[data-tab="' + attr + '"]').addClass('active');
       $('.vac-tab li').removeClass('active');
       $('.vac-tab li[data-tab="' + attr + '"]').addClass('active');
   });

   // masked
   $('.mask-phone').mask('+7(999)999-99-99');

   // form checked
    $('.checkbox-check').change(function() {
      if(this.checked) {
          $(this).closest('form').find('.btn-checkbox').removeClass('btn-checkbox-disabled');
      }
      else {
          $(this).closest('form').find('.btn-checkbox').addClass('btn-checkbox-disabled');
      }
    });




    $('.custom-select-head').on('click', function () {
        $(this).closest('.custom-select-wrapper').toggleClass('focus');
    });

    $('.custom-select-list li').on('click', function () {
        $(this).closest('.custom-select-list').find('li').removeClass('selected');
        $(this).addClass('selected');
        $(this).closest('.custom-select-wrapper').removeClass('focus');
        let value = $(this).attr('data-value');
        $(this).closest('.custom-select-wrapper').find('.custom-select-head span').text(value);
        $(this).closest('.custom-select-wrapper').find('select option:selected').attr('selected', null);
        $(this).closest('.custom-select-wrapper').find('select option[value="' + value + '"]').attr("selected", "selected");
    });

    $(document).mouseup(function (e){
        let div = $(".custom-select-wrapper.focus");
        if (!div.is(e.target)
            && div.has(e.target).length === 0) {
            $('.custom-select-wrapper.focus').removeClass('focus');
        }
    });

    // Collapse in section Question
      $('.accord-link-btn').on('click', function () {
        if ( $(this).closest('.js-answ-card').hasClass('active') ) {
          $('.js-answ-body').slideUp();
          $('.accord-link-btn').removeClass('active');
          $('.js-answ-card').removeClass('active');
        } else {
          $('.js-answ-body').slideUp();
          $('.accord-link-btn').removeClass('active');
          $('.js-answ-card').removeClass('active');
          $(this).closest('.js-answ-card').find('.js-answ-body').slideDown();
          $(this).closest('.js-answ-card').addClass('active');
          $(this).addClass('active');
        }
      });

    // pop-ups
    function thnx () {
        $overlayPopUpWRP.addClass('active');
        $('body, html').addClass('active');
        $popUpGeneralBlock.removeClass('active');
        $('#thnx').addClass('active');
    };
    $('.open-pop-thnx').click(function (e) {
        $overlayPopUpWRP.addClass('active');
        $('body, html').addClass('active');
        $popUpGeneralBlock.removeClass('active');
        $('#thnx').addClass('active');
    });

    // resume pop-up
    $('.open-pop-resumePopUp').click(function (e) {
        $('.pop-up-overlay-wrapper').addClass('active');
        $('body, html').addClass('active');
        $('.pop-up-general-block').removeClass('active');
        $('#resumePopUp').addClass('active');
    });
    // recom pop-up
    $('.open-pop-recomendPopUp').click(function (e) {
        $('.pop-up-overlay-wrapper').addClass('active');
        $('body, html').addClass('active');
        $('.pop-up-general-block').removeClass('active');
        $('#recomendPopUp').addClass('active');
    });

    // close all
    $('.overlay-pop-up').click(function (e) {
        $('.pop-up-overlay-wrapper').removeClass('active');
        $('body, html').removeClass('active');
        $('.pop-up-general-block').removeClass('active');
    });
    $('.pop-up-general-block-close-btn').click(function (e) {
        $('.pop-up-overlay-wrapper').removeClass('active');
        $('body, html').removeClass('active');
        $('.pop-up-general-block').removeClass('active');
    });

   // drag & drop
   var $fileInput = $('.file-input');

   // highlight drag area
   $fileInput.on('dragenter focus click', function() {
     $(this).closest('.file-drop-area').addClass('is-active');
   });

   // back to normal state
   $fileInput.on('dragleave blur drop', function() {
     $(this).closest('.file-drop-area').removeClass('is-active');
   });

   // change inner text
   $fileInput.on('change', function() {
       var thisInput = $(this);
     var filesCount = $(this)[0].files.length;
     var filesSize = this.files[0].size;
     var filesSizeKb;
     var $textContainer = thisInput.closest('.file-drop-area').find('.file-msg');

   if (filesCount > 0)  {
       if (filesSize > 10048576) {
           thisInput.closest('.file-drop-area').find('.drop-success').addClass('hidden');
           thisInput.closest('.file-drop-area').find('.drop-error').removeClass('hidden');
           thisInput.val('');
       } else {
           thisInput.closest('.file-drop-area').addClass('active');

           thisInput.closest('.file-drop-area').find('.drop-success').removeClass('hidden');
           thisInput.closest('.file-drop-area').find('.drop-error').addClass('hidden');

           thisInput.closest('.file-drop-area').find('.remove').addClass('active');
           thisInput.closest('.file-drop-area').find('.fake-btn').addClass('hidden');

           filesSizeKb = filesSize / 1024;
           thisInput.closest('.file-drop-area').find('.file-size').addClass('active').text(filesSizeKb.toFixed() + ' Кб');;

           if (filesCount === 1) {
           // if single file is selected, show file name
               var fileName = $(this).val().split('\\').pop();
               $textContainer.addClass('active').text(fileName);
           } else {
               // otherwise show number of files
               $textContainer.text('Выбрано файлов: ' + filesCount);
           }
       }
     }
   });
   $('.remove').click(function () {
       $(this).closest('.file-drop-area').removeClass('active');
       $(this).removeClass('active');
       $(this).closest('.file-drop-area').find('.file-size').removeClass('active').text('');
       $(this).closest('.file-drop-area').find('.file-input').val('');
       $(this).closest('.file-drop-area').find('.file-msg').removeClass('active').text('резюме');
       $(this).closest('.file-drop-area').find('.fake-btn').removeClass('hidden');
   });


});
